/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    update_breadcrumb();
    update_product_list_select();
    init_social_slider();
    init_coupon2ModalSwiper();
    init_checkout_help();

    $(window).resize(function() {
        update_breadcrumb();
    });

    $('.navbar-toggler').on('click', function(e) {
        e.preventDefault();

        if ($('#main-menu-wrp').hasClass('show')) {
            $('body').removeClass('menu-open');
        } else {
            $('body').addClass('menu-open');
        }

        $('#main-menu-wrp').collapse('toggle');
    });
    $('.menu-backdrop').on('click', function(e) {
        e.preventDefault();

        $('#main-menu-wrp').collapse('hide');
        $('body').removeClass('menu-open');
    });
});

function init_social_slider() {
    if ($('.social-swiper').length) {
        var social_swiper = new Swiper('.social-swiper', {
            slidesPerView: "2.1",
            spaceBetween: 6,
            breakpoints: {
                768: {
                    slidesPerView: "3.2",
                },
                992: {
                    slidesPerView: 4,
                },
            }
        });
    }
}

function init_coupon2ModalSwiper() {
    if ($('.coupon2ModalSwiper').length) {
        var myModalEl = document.getElementById('coupon2Modal')
        $('#coupon2Modal').on('shown.bs.modal', function(e) {
            // $('.your-class').slick('setPosition');
            // $('.wrap-modal-slider').addClass('open');
            var coupon2ModalSwiper = new Swiper('.coupon2ModalSwiper', {
                slidesPerView: 1,
                spaceBetween: 7,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                }
            });
        })
    }
}

function init_checkout_help() {
    $('.act-help-popup').on('click', function(e) {
        e.preventDefault();

        $('.checkout-help').addClass('open');
        $('body').css('overflow', 'hidden');
        $('body').addClass('help-open');
    });

    $('.checkout-backdrop, .checkout-help-popup .close-btn').on('click', function(e) {
        e.preventDefault();

        $('.checkout-help').removeClass('open');
        $('body').css('overflow', '');
        $('body').removeClass('help-open');
    });
}